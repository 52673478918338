<template>
    <ContainerContent
        :full-width="isFullWidth"
        :align-left="true"
        class="page-layout"
        :class="layoutClasses"
    >
        <div v-if="showLeftSlot" class="left-container">
            <slot name="left" />
        </div>

        <div class="content-container">
            <slot name="content" />
        </div>

        <div v-if="showRightSlot" class="right-container">
            <slot name="right" />
        </div>
    </ContainerContent>
</template>

<script>
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import {
    LAYOUT_TYPE_2_COLUMNS_LEFT,
    LAYOUT_TYPE_2_COLUMNS_LEFT_BREADCRUMBS,
    LAYOUT_TYPE_2_COLUMNS_RIGHT,
    LAYOUT_TYPE_3_COLUMNS,
    LAYOUT_TYPE_EMPTY,
    LAYOUT_TYPES,
} from '@types/CmsPage';

export default {
    name: 'PageLayout',

    components: {
        ContainerContent,
    },

    props: {
        type: {
            type: String,
            default: LAYOUT_TYPE_EMPTY,
            validator: value => Object.values(LAYOUT_TYPES).includes(value),
        },
    },

    computed: {
        layoutClasses() {
            const { showLeftSlot, showRightSlot } = this;

            return {
                'columns-3': showLeftSlot && showRightSlot,
                'columns-2':
                    (showLeftSlot && !showRightSlot) ||
                    (!showLeftSlot && showRightSlot),

                'sidebar-left': showLeftSlot && !showRightSlot,
                'sidebar-right': !showLeftSlot && showRightSlot,
            };
        },

        showLeftSlot() {
            return [
                LAYOUT_TYPE_2_COLUMNS_LEFT_BREADCRUMBS,
                LAYOUT_TYPE_2_COLUMNS_LEFT,
                LAYOUT_TYPE_3_COLUMNS,
                LAYOUT_TYPE_3_COLUMNS,
            ].includes(this.type);
        },

        showRightSlot() {
            return [
                LAYOUT_TYPE_2_COLUMNS_RIGHT,
                LAYOUT_TYPE_3_COLUMNS,
            ].includes(this.type);
        },

        isFullWidth() {
            return this.type === LAYOUT_TYPE_EMPTY;
        },
    },
};
</script>

<style lang="scss" scoped>
.page-layout {
    @apply flex flex-col mb-6;
}

.left-container,
.content-container {
    @apply w-full;
}

@screen lg {
    .page-layout {
        @apply flex-row mb-8;
    }

    .columns-2 {
        .content-container {
            @apply w-5/6;
        }

        &.sidebar-left {
            .left-container {
                @apply w-1/6;
            }
        }

        &.sidebar-right {
            .right-container {
                @apply w-1/6;
            }
        }
    }

    .columns-3 {
        .content-container {
            @apply w-4/6;
        }

        .left-container,
        .right-container {
            @apply w-1/6;
        }
    }
}
</style>
